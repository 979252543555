* {
  box-sizing: border-box;
}
.spawn-container {
  display: inline-flex;
  height: 100vh;
  overflow-x: auto;
  flex-wrap: nowrap;
  overflow-y: hidden;
}
.spawn-child {
  position: relative;
  width: 100vw;
  padding: 0.5rem;
  border: 1px solid grey;
}
.spawn-control {
  position: absolute;
  right: 15px;
  top: 15px;
}
.spawn-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.spawn-name {
  font-size: 1.15rem;
}
.spawn-add-btn {
  font-size: 1.15rem;
  padding: 2px 5px;
  border: 2px solid grey;
  border-radius: 5px;
  cursor: pointer;
}
