#root {
  width: 100%;
  min-height: 100%;
}
.App {
  min-height: 100%;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.heading {
  padding: 5px 0;
  text-decoration: underline;
}
button:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.775rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 54px;
  padding: 3px 12px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(25, 118, 210, 0.5);
  color: #1976d2;
  margin: 0px 0px 0px 16px;
}
.btn.--icon {
  min-width: 32px;
  padding: 8px;
}
.btn.--icon svg {
  fill: #1976d2;
}
.btn:first-child {
  margin-left: 0; /* remove the left from first */
}
.btn:last-child {
  margin-right: 0; /* remove the right from last*/
}
.btn.--xs {
  text-transform: capitalize;
  font-size: 0.715rem;
  line-height: 1.25;
  min-width: 70px;
}
.btn-group-pill {
  margin: 0px 0px 10px 16px;
}
.btn-group {
  display: flex;
  align-items: center;
}
.btn-group .btn:not(:last-of-type) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.btn-group .btn:not(:first-of-type) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

.btn-group-heading {
  font-size: 14px;
  text-align: center;
}
.user-list,
.user-media {
  margin: 10px 0;
  /* height: calc((100vh - 125px) / 2); */
}
.user-list-table {
  padding: 15px 10px;
}
.user-list-table .row {
  display: flex;
  width: 100%;
  align-items: stretch;
  flex-direction: row;
  margin: 10px 0;
  flex-wrap: wrap;
}
.row.--divider {
  height: 2px;
  background: #e8e8e8;
}
.row.--end {
  justify-content: end;
}
.user-list-table .col {
  display: flex;
  align-items: flex-end;
}
.user-list-table .status-col,
.user-list-table .uid-col {
  flex: 1;
}
.user-list-table .uid-col {
  justify-content: right;
}
.user-list-table .actions-col {
  flex: 2;
  justify-content: flex-end;
}
.status-badge {
  padding: 4px;
  width: 125px;
  border-radius: 5px;
  background-color: #888;
  color: #fff;
  text-align: center;
}
.overflow-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
}
.status-badge.subscribed {
  height: 30;
  min-width: 100;
  background-color: green;
}
.status-badge.waiting {
  height: 30;
  min-width: 100;
  background-color: yellow;
}
/* user media css */
.user-media-table .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 0;
}
.media-card {
  width: 320px;
  margin: 10px;
  margin-right: 15px;
  border: 1px solid #000;
}
.media-card > .--header {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.media-card .--scores {
  display: flex;
  flex-direction: column;
  padding: 5px;
  box-sizing: border-box;
}
.media-card .--quality {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  box-sizing: border-box;
  font-size: 14px;
}
.media-card .--state-desc {
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 125px;
  overflow: hidden;
  text-align: right;
  font-weight: 700;
}
.--scores-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  font-size: 12px;
}

.media-body {
  width: 300px;
  min-height: 250px;
  border: 1px solid #000;
  margin: 10px;
}

.media-body.--xs {
  width: 225px;
  min-height: 225px;
}

.media-body .--video {
  height: 150px;
}
.media-body .--actions {
  display: flex;
  align-items: center;
  margin: 10px;
  margin-top: 15px;
  flex-direction: row;
  justify-content: space-between;
}
.--actions.--column {
  flex-direction: column;
}
.media-body.--xs .--actions {
  margin: 5px;
  font-size: 12px;
}
.remote-video-container {
  height: 100%;
  width: 100%;
  background-color: yellow;
  text-align: center;
}

.local-video-contianer {
  height: 100%;
  width: 100%;
  background-color: pink;
  text-align: center;
}

/*  */
.inputContainer {
  display: flex;
  margin-left: 20px;
  padding-inline: 20px;
  border-radius: 20px;
}
.inputTitle {
  font-weight: bold;
  align-self: center;
  padding-right: 5px;
}
.inputTitle:not(:first-of-type) {
  margin-left: 10px;
}
.input {
  width: 200px;
  padding: 5px;
  align-self: center;
  justify-self: center;
}
/* Slider */
.slidecontainer {
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slidecontainer .--slider {
  width: 80px;
  height: 6px;
  cursor: pointer;
}
.user-media-table {
  padding: 15px 10px;
}
.user-score-table {
  width: 100%;
  border-bottom: 1px solid #eee;
  margin-bottom: 5px;
}

.user-score-table .cell-right {
  text-align: right;
}
